import { CanDeactivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { PageFlowFreeResources } from './page-flow-free-resources.action';
import { map } from 'rxjs';

export const pageFlowFreeResourcesGuard: CanDeactivateFn<never> = () => {
  return inject(Store)
    .dispatch(new PageFlowFreeResources())
    .pipe(map(() => true));
};
